import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { Col, Row } from "reactstrap";
import { ReactstrapInput } from "reactstrap-formik";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Button } from "reactstrap";
import "../styles.scss";

const LogInForm = (props) => {
  const [apiError, setApiError] = useState(null);
  let errorMessage;
  if (apiError) {
    errorMessage = (
      <div
        class="alert alert-danger col-12 col-md-10 col-lg-8 col-xl-6"
        role="alert"
      >
        {apiError}
      </div>
    );
  }
  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
        sso: props.sso,
        sig: props.sig,
        source: "discourse",
      }}
      onSubmit={(values) => {
        axios({
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
          url: "https://onecpd.rcvs.org.uk/discourse-sso/",
          data: values,
        })
          .then((response) => {
            window.location.href = response.data;
          })
          .catch((error) => {
            setApiError(error.response.data);
            console.log(error);
          });
      }}
    >
      <Form>
        <Row>
          <Col md="10" lg="8" xl="6" xs="12">
            <Field
              style={{
                display: `flex`,
                flexDirection: `column`,
                align: "left",
                width: "350px",
              }}
              id="username"
              type="text"
              label="Username"
              name="username"
              id="username"
              component={ReactstrapInput}
            />
          </Col>
        </Row>
        <Row>
          <Col md="10" lg="8" xl="6" xs="12">
            <Field
              style={{
                display: `flex`,
                flexDirection: `column`,
                align: "left",
                width: "350px",
              }}
              type="password"
              label="Password"
              name="password"
              id="password"
              component={ReactstrapInput}
            />
          </Col>
          <Col xs="12">
            <Button type="submit" color="primary">
              Log in
            </Button>
            <br />
            <br />
            {errorMessage}
            <p>
              If you've forgotten your RCVS My Account credentials, you can{" "}
              <a
                href="https://myaccount.rcvs.org.uk/"
                target="_blank"
                rel="noreferrer"
              >
                reset your username or password
              </a>
              .
            </p>
          </Col>
        </Row>
      </Form>
    </Formik>
  );
};

export default LogInForm;
