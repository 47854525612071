import * as React from "react";
import LogInForm from "../components/LogInForm.js";
import Footer from "../components/footer";
import Banner from "../components/banner";
import NavBar from "../components/NavBar";
import { useLocation } from "@reach/router";
import { Container } from "reactstrap";
import "../styles.scss";

const queryString = require("query-string");

const IndexPage = () => {
  const location = useLocation();
  const urlParams = queryString.parse(location.search);
  const sso = urlParams.sso;
  const sig = urlParams.sig;
  return (
    <>
      <body class="body">
        <Banner />
        <NavBar />
        <main class="main">
          <Container style={{ paddingTop: "5px" }}>
            <div class="row justify-content-md-center">
              <div class="col-12 col-md-8">
                <title>RCVS Discourse SSO</title>
                <h1 class="headingStyles">RCVS Discourse Forum</h1>
                <p>
                  Please log in with your MyAccount details.
                </p>

                <LogInForm sso={sso} sig={sig} />
              </div>
            </div>
          </Container>
        </main>
        <Footer />
      </body>
    </>
  );
};

export default IndexPage;
