import React from "react";

const NavBar = () => (
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <div id="no-collapse-navbar">
      <span class="navbar-text"></span>
    </div>
  </nav>
);

export default NavBar;
