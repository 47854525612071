import React from "react";

const Footer = () => (
  <footer>
    <span>
      <a href="https://www.rcvs.org.uk/" target="_blank">
        Royal College of Veterinary Surgeons, The Cursitor, 38 Chancery Lane, London, WC2A 1EN
      </a>
    </span>

    <span class="float-right">
      © Copyright{" "}
      <a href="https://www.rcvs.org.uk/" target="_blank">
        RCVS
      </a>
      2021
    </span>
  </footer>
);

export default Footer;
