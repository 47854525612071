import React from "react";

const Banner = () => (
  <div id="top_banner">
    <a href="/" name="home">
      <img
        src="https://onecpd.rcvs.org.uk/static/images/logo.b471a50694f8.svg"
        class="logo"
        alt="RCVS logo"
      />
    </a>
  </div>
);

export default Banner;
